<template>
  <div class="card">
    <div class="card-title">
      <div class="card-img-title">
        <img src="@/assets/icon/icon_e7fbmzzmou9/cetizhong.png" style="width:20px;height:auto" alt="" />
        <span class="title"> 仪表盘</span>
      </div>
      <div>
        <a-icon type="reload" @click="reload" />
      </div>
    </div>
    <a-spin :spinning="loading">
      <div class="card-content status-content">
      <div class="left">
        <div>
          <div class="task-border-left">
            <div class="task-count left">
              {{ firstData.task && firstData.task.day }}
              <!--  <div class="ray"></div> -->
            </div>
          </div>
          <div class="task-text">
            <div>今日已完成</div>
            <div>真棒！</div>
          </div>
        </div>
        <div class="month-task">本月已完成任务数量：{{ firstData.task && firstData.task.month }}</div>
      </div>
      <div class="right">
        <div>
          <div class="task-border-right">
            <div class="task-count right">
              {{ firstData.task && firstData.task.wait }}
              <!-- <div class="ray"></div> -->
            </div>
          </div>
          <div class="task-text">
            <div>今日待完成</div>
            <div>加油哦！</div>
          </div>
        </div>
        <div class="month-task"></div>
      </div>
    </div>
    </a-spin>
    
  </div>
</template>

<script>
export default {
  props: {
    firstData: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    reload() {
      this.$emit('getFirstData')
    },
    palyRay() {
      let timer = setTimeout(() => {
        if (this.firstData.task && !this.firstData.task.day) {
          document.getElementsByClassName('ray')[0].style.left = '80px'
        }
        if (this.firstData.task && this.firstData.task.wait) {
          document.getElementsByClassName('ray')[1].style.left = '80px'
        }
        clearTimeout(timer)
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@assets/less/homeStyle.less';
</style>
